import React from "react";

const educationContent = [
  {
    year: "Social Media",
    
    details: `  Generation of content for social networks such as photography, video, animation and graphic design.`,
  },
  {
    year: "Animation",
    
   
    details: `2D animation 3D and Motion Graphics.`,
  },
  {
    year: "Live Events",
    
   
    details: `Support for live streaming through platforms such as: YouTube, Skype and Facebook. `,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-check-square-o"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
