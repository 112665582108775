import React from "react";

const personalInfoContent = [
  { metaInfo: "VISIÓN HYBRIDA" },
  
  { meta: "TIME ACTIVE:", metaInfo: "14 Years" },
  { meta: "LOCATION:", metaInfo: "Mexico" },
  
  { meta: "CITY:", metaInfo: "Juarez City" },
  { meta: "PHONE:", metaInfo: "+52-656-167-7023" },
  { meta: "EMAIL:", metaInfo: "contacto@visionhybrida.com" },
  
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta} </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
